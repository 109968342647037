@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');

.Page {
  background-color: #f7f6f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  color: #1C1C1C;
  letter-spacing: .04em;
  font-size: 20px;
  white-space: pre-wrap;
}

.Header {
  width: 100%;
  height: 100px;
}

.Content {
  flex-grow: 1;
  width: 100%; 
  margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
  .Content {
    margin-bottom: 20px;
  }
}