@import url("https://fonts.googleapis.com/css2?family=Miniver&family=Source+Sans+Pro:wght@300;400&display=swap");

.AboutLayout {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.AboutDoublePanel {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.AboutDoublePanel--last {
  opacity: 0;
}

.HiddenArrow {
  opacity: 0;
}

.AboutArrowSectionTop {
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f5;
  opacity: 1;
  animation-name: fadeInOpacitySlow;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.AboutArrowSectionBottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f5;
  opacity: 1;
  animation-name: fadeInOpacitySlow;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.AboutArrowSectionLeft {
  position: absolute;
  height: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f5;
  opacity: 1;
  animation-name: fadeInOpacitySlow;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.AboutArrowSectionRight {
  position: absolute;
  height: 100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f5;
  opacity: 1;
  animation-name: fadeInOpacitySlow;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacitySlow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.UpArrow {
  height: 25px;
  transform: rotate(180deg);
  z-index: 1;
  cursor: pointer;
}

.DownArrow {
  height: 25px;
  margin-bottom: 30px;
  z-index: 1;
  cursor: pointer;
}

.LeftArrow {
  height: 25px;
  margin-left: 50px;
  transform: rotate(90deg);
  z-index: 1;
  cursor: pointer;
}

.RightArrow {
  height: 25px;
  margin-right: 50px;
  transform: rotate(270deg);
  z-index: 1;
  cursor: pointer;
}

.AboutHeader {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.AboutHeaderText {
  font-family: "Miniver", sans-serif;
  font-size: 40px;
  text-align: center;
  max-width: 180px;
}

.AboutContent {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 5%;
  margin-right: 5%;
}

.AboutContentLink {
  color: #7351fa;
  z-index: 3;
}

.AboutContentText {
  margin-right: 100px;
}

.SlideUp {
  animation-name: slideUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.SlideUpOut {
  opacity: 1;
  animation: slideUpOut 0.5s ease-in;
}

@keyframes slideUpOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.SlideDown {
  animation-name: slideDown;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.SlideDownOut {
  opacity: 1;
  animation: slideDownOut 0.5s ease-in;
}

@keyframes slideDownOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.SlideRight {
  animation-name: slideRight;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.SlideRightOut {
  opacity: 1;
  animation: slideRightOut 0.5s ease-in;
}

@keyframes slideRightOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.SlideLeft {
  animation-name: slideLeft;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.SlideLeftOut {
  opacity: 1;
  animation: slideLeftOut 0.5s ease-in;
}

@keyframes slideLeftOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .AboutHeaderText {
    font-size: 35px;
  }

  .AboutContentText {
    font-size: 16px;
  }

  .DownArrow {
    height: 18px;
    margin-bottom: 5px;
  }

  .LeftArrow {
    height: 18px;
    margin-left: 20px;
  }

  .RightArrow {
    height: 18px;
    margin-right: 20px;
  }

  .UpArrow {
    height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .AboutHeader {
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .AboutHeaderText {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    max-width: 80%;
  }

  .AboutContent {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 0px;
  }

  .AboutContentText {
    margin: 0px 60px 0px 70px;
    font-size: 16px;
  }

  .DownArrow {
    height: 18px;
    margin-bottom: 5px;
  }

  .LeftArrow {
    height: 18px;
    margin-left: 20px;
  }

  .RightArrow {
    height: 18px;
    margin-right: 20px;
  }

  .UpArrow {
    height: 18px;
  }
}

@media screen and (max-width: 400px) and (max-height: 800px) {
  .AboutContentText {
    font-size: 13px;
  }
}
