.Row {
  height: 100%;
  margin-left: 2%;
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LinkSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Link {
  color: #1C1C1C;
  display: block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
}

.Link:first-child {
  margin-right: 50px;
}

.LinkText {
  flex: 1;
  top: 0px;
  left: 0px;
  position: relative;
  z-index: 2;
}

.LinkTextBig {
  transform: scale(1.07, 1.07);  
  transition: .1s;
}

.Logo:hover {
  transform: scale(1.05, 1.05);
  transition: .1s;
}

.LinkHoverIcon1 {
  position: absolute;
  top: -5px;
  left: -17px;
  height: 29px;
  z-index: 1;
}

.LinkHoverIcon2 {
  position: absolute;
  top: -3px;
  left: -14px;
  height: 29px;
  z-index: 1;
}

.AppLogo {
  height: 60px;
  z-index: 3;
}

.HeaderLink {
  color: #1C1C1C;
  text-decoration: none;
}

.FadeIn {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 600px) {
  .AppLogo {
    margin: 10px;
  }

  .LinkTextBig {
    transform: none;
  }
  
  .Logo:hover {
    transform: none;
  }
}