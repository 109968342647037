.DoublePanel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 600px) {
  .DoublePanel {
    flex-direction: column;
    justify-content: flex-start;
  }  
}