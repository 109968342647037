.HomeLeft {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  margin-bottom: 20%;
}

.HomeTextBox {
  max-width: 50%; 
}

.HomeRight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  margin-right: 10%;
  margin-bottom: 5%;
}

.HomePictureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%; 
}

.HomePicture {
  width: 100%;
  max-width: 400px;
  max-height: 100%;
}

@media screen and (max-width: 600px) {
  .HomeLeft {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0px;
    font-size: 16px;
  }
  
  .HomeRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 80%; 
    margin: 0px;
    height: 100%;
    width: 100%;
  }

  .HomePictureContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .HomePicture {
    margin-top: 30px;
  }

  .ContactLeft {
    margin: 0px;
  }

  .ContactLink {
    font-size: 16px;
  }

  .ContactLink:hover {
    transform: none;
  }
}