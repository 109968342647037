.ContactLeft {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  margin-bottom: 20%;
}

.ContactContent {
  height: 100%;
  width: 50%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 5%;
}

.ContactHeader {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.ContactHeaderText {
  font-family: "Miniver", sans-serif;
  font-size: 40px;
  text-align: center;
  margin-left: 100px;
}

.ContactLink {
  text-decoration: underline;
  color: #1c1c1c;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 3;
}

.LinkHoverIcon3 {
  position: absolute;
  top: 2px;
  left: -20px;
  height: 26px;
  z-index: 1;
}

.LinkHoverIcon3Small {
  position: absolute;
  top: 3px;
  left: -20px;
  height: 23px;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .ContactHeader {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .ContactHeaderText {
    font-size: 30px;
    margin-left: 0px;
  }

  .ContactContent {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 0px;
  }

  .ContactLeft {
    margin: 0px;
  }

  .ContactLink {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .LinkHoverIcon3 {
    display: none;
  }

  .LinkHoverIcon3Small {
    display: none;
  }
}
